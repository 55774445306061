.scrolling-container {
    margin-top: 96px;
    margin-left: 15%;;
    margin-right: 15%;;
    width: auto;
    height: 72px;
    overflow: hidden;
    position: relative;
    background-color: #212121;
    border-top: .7px solid #32373D;
    border-bottom: .7px solid #32373D;

  }
  
  .scrolling-text {
    position: absolute;
    white-space: nowrap;
    animation: scrollText 22s linear infinite;
    font-size: 20px;
    color: #C5E3FE;    
  }
  
  @keyframes scrollText {
    0% {
      left: 100%;
    }
    100% {
      left: -100%;
    }
  }

  @media (max-width: 810px) {

    .scrolling-container {
        margin-top: 48px;
        margin-bottom: 24px;
        margin-left: 24px;
        margin-right: 24px;
        height: 60px;

    }

    .scrolling-text {
        font-size: 16px; 
        animation: scrollText 15s linear infinite;
       
      }
  }