@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700;800&display=swap');


body {
    font-family: 'Karla', sans-serif;

  }
  
.header {
font-family: 'Karla', sans-serif;
font-weight: 700;
font-size: large;
}

.extra-bold {
font-family: 'Karla', sans-serif;
font-weight: 800;
}