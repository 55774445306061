.bio-divider {
    text-align: left;
    margin-left: 7%;;
    margin-right: 7%;;
    margin-bottom: 96px;
    border-bottom: .7px solid #C5E3FE; /* Sets a 2px solid black bottom border */
    position: relative;
    z-index: 0;
    /* margin-top: 60vh; */
}

.resume-button p{
    margin-left: 5%;
    margin-right: 0px;
    display: flex;
}

.resume-button {
    margin-left: 6% !important;
    display: flex;
    margin-top: 24px;
}

#landing-page-link {
    display: flex;
    margin-left: 0px;
    justify-content: flex-start;
}

.bio-description {
    opacity: 75%;
}


.bio-divider h1 {
    color: #C5E3FE;
}

.button {
    transition: all 0.5s ease;
}


.button:hover {
    box-shadow: 5px 5px 0 #C5E3FE;
    transform: translateX(0px) translateY(-5px);
    transition: all 0.5s ease;
}



.bio-content {
    text-align: left;
}

.bio-content h1, .bio-description {
    color: #C5E3FE;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 0%;
}

.bio-content h2 {
    font-weight: normal;
    line-height: 1.5;
    font-size: 24px;
    margin-top: 0%;
    
}

.bio-position {
    opacity: 50%;
}

.bio-img {
    width: 64px;
    width: 50px;
}

.bio-thumbnail {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-left: 7%;
}

.bio-name p{
    margin-left: 12px;
    line-height: .1;
    margin-top: 12px;
    white-space: nowrap;
}

.position, .location {
    opacity: 50%;
    margin-left: 7%;
}

 .button {
    background-color: #212121;
    border-color: #C5E3FE;
    padding: 12px 24px;
    border-radius: 48px;
    cursor: pointer;
    font-weight: bold;
    border-style: solid;
    white-space: nowrap;
    border-width: .7px;
}

.button p {
    margin-top: 0px;
}

#landing-page-link img {
    margin-top: 0px;
}

 .button{
    color: #C5E3FE;   
    margin-left: 7%;
    margin-right: 0%;
    border-width: .7px;
    width: auto;
}

.button p {
    margin-left: 0%;
    margin-right: 0%;
}

.education-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    margin-left: 7%;
    flex-direction: column;

}

.education-content img {
    margin-top: 24px;
}

.education-info {
    display: flex;
    align-items: center;
}

.education-text {
    text-align: left;
    line-height: 0;
    align-items: center;
    height: fit-content;

}

.education-text p, h2{
    margin-left: 24px;
    line-height: 1;
    color: #C5E3FE;
}

.education-text p {
   /* white-space: nowrap; */
}


#downloadicon {
    margin-bottom: -5px;
    margin-left: 5px;
    width: 20px;
}

#mobile-bio-img {
    display: none;
}

#desktop-edu-img {
    width: 64px;
    margin-left: 0%;

}

.bio-content {
    margin-top: 96px;
}




@media (max-width: 810px) {

    #mobile-bio-img {
        display: block;
    }

    .bio-thumbnail {
        display: flex;
        align-items: left;
        justify-content: left;
        margin-left: 5%;
    }
    

    #desktop-bio-img {
        display: block;
    }

    #desktop-edu-img {
        display: none;
    }

    .intro-text {
        font-size: 20px;
    }

    .bio-divider {
        text-align: left;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 24px;
        margin-top: 110vh;

    }

    .bio-content h1, h2, p {
        margin-left: 24px;
        margin-right: 24px;
    }

    .education-text h2 {
        font-size: 20px;
    }

    .bio-img {
    }

    .bio-name p{
        margin-left: 12px;
        line-height: .1;
    }

    .bio-description {
        margin-left: 24px;
        margin-right: 24px;
        opacity: 75%;
    }

    .bio-content h2 {
        line-height: 1.5;
        font-size: 16px;

    }

    .bio-content {
        /* margin-top: 128px; */
    }

    .bio-content h1 {
        margin-top: 48px;

    }

    .bio-img {
        width: 48px;
    }

    .button {
        background-color: #212121;
        border-color: #C5E3FE;
        padding: 12px 24px;
        border-radius: 48px;
        cursor: pointer;
        font-weight: bold;
        border-style: solid;
        width: fit-content;
        border-width: .7px;
        transition: all 0.5s ease;

    }

    .button:hover {
        box-shadow: 5px 5px 0 #C5E3FE;
        transform: translateX(0px) translateY(-5px);
        transition: all 0.5s ease;
    }
    
    .button{
        color: #C5E3FE;   
        margin-left: 24px;
        margin-right: 24px;
        border-width: .7px;
    }

    .education-content {
        display: block;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        margin-left: 0%;
    }

    .intro-text {
        /* margin-top: 48px; */
    }

    .education-text h2 {
        font-size: 20px;
        line-height: 1.5;
    }


    .education-text p, h2{
        margin-left: 24px;
        line-height: 1;
        color: #C5E3FE;
    }

    .resume-button p{
        margin-left: 0%;
        margin-right: 0px;
    }



    .position, .location {
        opacity: 50%;
        margin-left: 24px;
    }




}