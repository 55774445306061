html, body {
    height: 100%;
    margin: 0;
    background-color: #212121;
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

#about-modifier {
    margin-top: 64px;
}


body {
    display: flex;
    flex-direction: column;
    text-align: center;
}

h1, h2 {
    font-family: Karla;
    color: #C5E3FE !important;
    font-weight: 800;
    line-height: 1;
}

h3, h4 {
    font-family: Karla;
    color: #C5E3FE !important;
}

p, li {
    font-family: Karla;
    color: #C5E3FE !important;
}

Link {
    color: #C5E3FE !important;
    font-size: 16px !important;
}

.content {
    flex: 1;
}

a:link {
    color: #212121;
    background-color: transparent;
    text-decoration: none;
  }


.border-line {
    text-decoration: solid;
    box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10);
}

#btn:hover {
    background-color:  #EFEFEF;
    color: #101720;
    cursor: pointer;
    transition: 0.3s;
    border-style: solid;
    border-color: #101720;
    border-width: 3px;
}

a:visited {
    color: #212121; /* Link color after it has been visited */
    
}

.landing-page-link {
    color: #101720;
  }

.landing-page-link {
    text-decoration: none;
    display: inline-block;
}

