

.navbar-menu {
  float: right;
  display: flex;
  position: relative;
  z-index: 2;
}

.navbar-menu #landing-page-link {
  margin-left: 24px;
}


.logo {
  position: relative;
  z-index: 0;
}

.link {
  margin-left: 0px !important;
  opacity: 1;
}

.link a {
  opacity: 1 !important;
}

.logo img {
  width: 48px;
}

.logo-img img{
  width: 75px;
  margin-top: 24px;

}

.logo-img {
  margin-top: -96px;
}

.navbar-menu a {
  padding-bottom: 0px;
  margin-left: 24px;
  font-weight: 600;
  font-size: 16px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky; /* For Safari */
  align-items: center; 
  padding-left: 7%;
  padding-right: 7%;
  z-index: 1;
  margin-top: 12px;
  margin-bottom: 48px;
  position: relative;


}

.navbar-right-container {
  display: flex;
  align-items: center;
  background-color: #212121;
  padding: 16px;
  padding-right: 24px;
  border-radius: 48px;
  transition: all 0.5s ease;

}

.navbar-right-container a {
  color: #C5E3FE;
  opacity: 50%;
  transition: all 0.5s ease;

}

.navbar-right-container a:hover {
  color: #C5E3FE;
  opacity: 100%;
}

.navbar-right-container:hover {
  box-shadow: 5px 5px 0 #C5E3FE;
  transform: translateX(0px) translateY(-5px);
  transition: all 0.5s ease;
}



.mobile-navbar-menu {
  display: none;
}

.hamburger-container {
  cursor: pointer;
  position: relative;
  right: 0;
  top: 0;
}

.navbar-right-container img{
  width: 25px;
}

@media (max-width: 810px) {

  .navbar-menu {
    display: none;
  }


  .mobile-navbar-menu {
    position: fixed; /* Sticks the navbar in a fixed position */
    bottom: 0; /* Places it at the bottom of the viewport */
    left: 50%; /* Centers horizontally using the left edge */
    transform: translateX(-50%); /* Shifts it back by half its width for perfect centering */
    width: auto; /* Automatically adjusts to the content */
    display: flex; /* Flexbox layout for alignment */
    justify-content: space-around; /* Even spacing for the links */
    padding: 10px 20px; /* Adds padding */
    border-radius: 10px; /* Optional: Rounded corners for the bar */
    z-index: 1; /* Ensures it stays on top of other content */
    
  }

  .mobile-navbar-right-container {
    display: flex;
    align-items: center;
    background-color: #212121;
    border-radius: 32px;
    padding: 8px;
    gap: 24px;
    width: 244px;
    justify-content: center;
    margin-bottom: 48px;
    border: solid .7px #C5E3FE;
  }

  .mobile-navbar-right-container:hover {
      box-shadow: 5px 5px 0 #C5E3FE;
      transform: translateX(0px) translateY(-5px);
      transition: all 0.5s ease;
  }

  .mobile-navbar-right-container img{
    width: 25px;
    opacity: 50%;
    transition: all 0.5s ease;

  }

  .mobile-navbar-right-container img:hover{
    opacity: 100%;
  }

  .mobile-navbar-menu a {
    padding-bottom: 0px;
    margin-left: 0px;
    font-weight: normal;
    font-size: 16px;
  }

  .logo img {
    margin-top: 8px;
    display: none;
  }

  .mobile-hamburger {
    margin-top: 0px;

  }

  .mobile-menu {
    z-index: 0;
    width: 100%;
    height: 100vh;
    margin-left: -24px !important;
    display: flex;
    justify-content: left;
    flex-direction: row-reverse;
    align-content: flex-start;
    align-items: center;
    flex-wrap: wrap;  
    top: 0px !important;
  }

  .mobile-menu a {
    color: #C5E3FE;

  }

  .hamburger-menu li{
    font-size: 20px !important;
    font-weight: normal;
  }




.hamburger-menu {
  list-style: none;
  padding: 0;
  margin-top: 64px;
  padding-bottom: 12px;
  padding-left: 32px;
}

.hamburger-menu li {
  width: 35px;
  height: 12px;
  margin: 48px 0;
  transition: 0.4s;
  display: flex;
  font-size: 32px;
  justify-content: left;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky; /* For Safari */
  align-items: center; 
  padding-left: 24px;


}







}

@media (min-width: 1025px) {



}