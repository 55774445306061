footer {
    justify-content: space-between;
    bottom: 0;
    position: relative;
    text-align: left;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 48px;
}

#coffee {
    width: 28px;
    padding-left: 2px;

}




.connect-title {
    font-size: 32px;
    color: #C5E3FE;
    margin-top: 0px;
    margin-bottom: 0px;
}

#quote {
    text-decoration: none;
    color: #C5E3FE;
    font-size: 16px;
}

.clipboard {
    display: flex;
    justify-content: left;
    align-items: center;
    max-height: fit-content;
    padding-top: 0px;
    margin-bottom: -20px;


}

.clipboard h4:hover {
    box-shadow: 3px 3px 0 #C5E3FE;
    transform: translateX(0px) translateY(-10px);
    transition: all 0.5s ease;
    
}

.clipboard h4 {
    color: #C5E3FE;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #212121; 
    border-radius: 48px;
    transition: all 0.5s ease;
    border-style: solid;
    border-width: .7px;
    border-color: #C5E3FE;
    width: fit-content;

}

.copy {
    margin-bottom: -5px;
    margin-left: 5px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    gap: 20px;
    margin-bottom: 24px;
}

.footer-links {
    padding-top: 24px;
    padding-left: 0px;
}

.footer-links a {
    margin-right: 12px;
    margin-left: 0px;
}

.footer-container {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
    align-items: center;
    align-content: center;
    margin-top: 96px;
    position: relative;
    z-index: 1;
    margin-left: 0%;
    margin-right: 0%;
    /* border-top: solid .7px #C5E3FE; */
    /* display: none; */
}

.footer-container p {
    margin-left: 0%;
    margin-right: 0%;

}

.footer-img {
    width: 200px;
}

.footer-container p {
    font-size: 16px;
}

.footer-container img {
    font-size: 12px;
}

.trademark {
    margin-left: 0%;
}

@media (max-width: 810px) {


    .footer-container {
        padding-bottom: 4px;
        margin-top: 96px;
        border-style: none;
        display: flex;
    }

    .connect-title {
        margin-top: 24px;
    }

    .footer-container p {
        font-size: 14px;
    }

    footer {
        
        margin-left: 24px;
        margin-right: 24px;
    
    }

    .footer-content {
        display: block;
        text-align: center;
    }
    

    .clipboard {
        display: flex;
        max-height: fit-content;
        margin-left: 24px;
        margin-right: 24px;
        justify-content: center;
    
    }

    .footer-img {
        width: 200px;
    }

    #quote {
        font-size: 14px;
    }

    .quote {
        margin-bottom: 64px;
    }

    .trademark {
        display: none;
    }

}