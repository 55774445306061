.sticky-navbar {
    position: relative;
    top: 48px;
    z-index: 1000;
}

#projects {
    padding-top: 20px;
}

.project-divider h1 {
    color: #C5E3FE;
    font-weight: normal;
}

.introduction-page {
    text-align: left;

}

.hot-links {
    text-decoration: none;
}

.hot-links img {
    width: 25px;
}

#indent {
    margin-left: 32px;
}

.plant {
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#vine-three {
    position: absolute;
    margin-top: -300px;
    left: 0;
    margin-left: -12px;
}

#vine-four {
    position: absolute;
    margin-top: 27vh;
    right: 0;
    margin-right: -12px;
}


.school {
    color: #51C447;
}

.icons-container img{
    margin-right: 12px;
}

.icons-container {
    margin-left: 28px !important;

}

.title {
    text-align: left;
    margin-left: 48px;
}

#vine-one {
    position: absolute;
    top: 0;
    margin-top: -100px;
    left: 0;
    margin-left: -12px;
}

#vine-two {
    position: absolute;
    top: 0;
    margin-top: 50vh;
    right: 0;
    margin-right: -12px;
}

.home-container {
    text-align: center;
    z-index: 1;
}

.green-font {
    color: #51C447;
    text-decoration: underline #51C447;
   
}

/* .gray-text {
    color: #a4a4a4;
}

#offer-one {
    opacity: .5;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
} */

#offer-one:active, #offer-one:hover {
    opacity: 1;
    /* transform: translateY(-25px); */
    transition: opacity 0.8s ease, transform 0.8s ease;
  }

.landing-page {
    background-image: url('../assets/skylinewallpaper.svg');
    background-size: contain;    /* Ensures the entire image is visible */
    background-position: center;
    background-repeat: no-repeat; /* No repeating */
    height: 110vh;
    width: 100vw;
    margin: 0;       /* Remove default margin */
    padding: 0;      /* Remove default padding */
    justify-content: center;  /* Centers content horizontally */
    align-items: center;      /* Centers content vertically */
    margin-bottom: -200px !important;
}

.main-title {
    color: #101720;
    font-size: 50px;
    font-family: Karla;
    letter-spacing: 0px;
    margin: 64px 32px 0px 32px;
    
}

.header {
    text-decoration: underline;
    font-weight: 700;
    font-family: Karla;
    font-size: 24px;
    margin-top: 32px;
}

.offer-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    
}

.offer1 {
    width: 75%;
    /* margin-top: 84px; */
    margin-bottom: 24px;

}

#plant-svg {
    margin-top: -75px;
}
#home {
    margin-top: -33px;
}

.hero-container {
    box-shadow: 0px 1px 1px 0px rgba(126, 126, 126, 0.10);
    padding-bottom: 16px;
}

#recent-work-title {
    margin-top: 24px;
    font-size: 16px;
}

.spaced {
    letter-spacing: 14px;
    margin-bottom: 7px;
}

.spinning-logo {
    animation: spin 7s infinite linear;
    transform-style: preserve-3d;
    padding-top: 50px;
}

@keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
}

.less-spaced {
    letter-spacing: 7px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#stars {
    width: 144px;
}

.slogan {
    word-spacing: 14px;
    padding-bottom: 0px;
    margin-top: 0px;
}


.flourescent {
    height: 25px;
    flex-shrink: 0;
    background: linear-gradient(90deg, #5D95A4 0%, #C97838 100%);
}



.row {
    padding: 32px;
}

.home {
    padding: 32px
}

.home-title {
    font-size: 32px;
    padding-top: 32px;
}

.subtitle {
    font-size: 26px;
}

.container {
    padding-top: 32px; 
}



/* Small screens (phones) */
@media (max-width: 600px) {
   #desktop-flour {
    display: none;
    }

    .main-title {
        color: #51C447;
        font-size: 64px;
        margin: 64px 32px 0px 32px;
        line-height: .8;
        letter-spacing: -4px;
        padding-top: 115px;
        margin-top: 124px;
        margin-left: 0px;
        margin-right: 0px;

    }

    #projects {
        padding-top: 5px;
    }

    .header {
        text-decoration: underline;
        font-weight: 700;
        font-family: Karla;
        font-size: 16px;
        margin-top: 32px;
        margin: 24px;
        margin-top: 24px;
        line-height: 1.5;
    }

    .landing-page {
        height: 140vh;
        width: 100vw;
        margin-bottom: -400px !important;

    }

    #vine-one {
        position: absolute;
        top: 0;
        margin-top: -175px;
        left: 0;
        margin-left: -12px;
    }
    
    #vine-two {
        position: absolute;
        top: 0;
        margin-top: 30vh;
        right: 0;
        margin-right: -12px;
    }

    .introduction-text {
        margin: 16px;
        margin-top: 32px;
        margin-bottom: 64px;
        margin-left: 32px;
    }

    #indent {
        margin-left: 0px;
    }

    .offer1 {
        width: 100%;
        margin-top: 64px;
        font-size: 10px;
    }

    #plant-svg {
        width: 100px !important;
        height: 100px !important;
        margin-top: -50px;
        margin-bottom: -50px;
    }
    

}


@media (min-width: 601px) and (max-width: 1024px) {
    
    .main-title {
        color: #51C447;
        font-size: 84px;
        margin: 64px 32px 0px 32px;
        line-height: .8;
        letter-spacing: -4px;
        padding-top: 125px;
        margin-top: 124px;
        margin-left: 84px;
        margin-right: 84px;

    }

    .introduction-text {
        margin: 16px;
        margin-top: 100px;
        margin-bottom: 100px;
        margin-left: 32px;

    }

    .header {
        text-decoration: underline;
        font-weight: 700;
        font-family: Karla;
        font-size: 16px;
        margin-top: 32px;
        margin: 64px;
        margin-top: 24px;
        line-height: 1.5;
    }

    .landing-page {
        height: 140vh;
        width: 100vw;
        margin-bottom: -300px !important;

    }


    #indent {
        margin-left: 32px;
    }

  }
  
  
@media (min-width: 1025px) {

    
}  

